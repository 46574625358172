import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Typography, IconText } from '@decide-ai/uikit';
import { LoaderContainer } from '../../../../components/uikit';
import { InfoItem } from '../InfoItem';
import { LoadingComponent } from '../LoadingComponent';
import { ErrorComponent } from '../ErrorComponent';

// Hooks
import { useEmailVerification } from '../../customHooks/useEmailVerification';

type EmailVerificationProps = {
  label: string;
  email: string;
};

export const EmailVerification: React.FC<EmailVerificationProps> = ({ 
  label,
  email,
}) => {
  const {
    statusBgColor,
    renderStatusMessage,
    showResendVerification,
    sendVerificationEmail,
    verificationStatusLoading,
    isVerificationButtonLoading,
  } = useEmailVerification(email);
  const { t } = useTranslation();

  if (!email) {
    return (
      <div className="flex flex-col rounded-lg gap-2">
        <InfoItem text={email} label={label} />
      </div>
    );
  }

  return (
    <LoaderContainer
      isLoading={verificationStatusLoading}
      transitionDuration={1}
      LoadingComponent={() => (<LoadingComponent email={email} label={label} />)}
      ErrorComponent={() => (<ErrorComponent />)}
    >
      <div className={cn("flex flex-col rounded-lg gap-2", statusBgColor, {
        "p-3": email
      })}>
        <InfoItem text={email} label={label} />

        {renderStatusMessage && (
          <Typography size="sm">
            {t(`app.pages.profile.view_mode.email_verification.${renderStatusMessage}`)}
          </Typography>
        )}

        {showResendVerification && (
          <div className="flex flex-col items-start gap-4">
            <IconText
              iconName="DangerCircleIcon"
              iconStroke="F80"
              text={t('app.pages.profile.view_mode.email_verification.no_verified')}
              textColor="F80"
              textSize="sm"
            />
            <Button
              buttonText={t('app.pages.profile.view_mode.button_text')}
              onClick={sendVerificationEmail}
              rounded="full"
              size="small"
              loading={isVerificationButtonLoading}
              skin="standard"
              theme="black"
            />
          </div>
        )}
      </div>
    </LoaderContainer>
  );
}
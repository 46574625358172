import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button, Picture } from '@decide-ai/uikit';

export const PromoBanner = () => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    const url = t('app.components.layout.footer.banner.button_link');
    window.open(url, '_blank');
  }

  return (
    <div className="relative bg-F00 overflow-hidden py-8 px-4 lg:px-14 rounded-xl">
      <Button
        buttonText={t('app.components.layout.footer.banner.button_text')}
        iconName="ArrowBendDoubleUpRightIcon"
        onClick={handleRedirect}
        rounded="full"
        size="medium"
        skin="standard"
        theme="blue"
      />

      <div className="w-1/2 absolute right-0 top-1/2 -translate-y-1/2">
        <Picture src="/assets/promo_banner.png" />
      </div>
    </div>
  );
}
import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { motion } from 'framer-motion';

// Components
import { Typography, WebLayoutIcon, SimpleUserBodyIcon } from '@decide-ai/uikit';

// Types
import { DesktopNavItemTypes } from '../../NavDesktop/MenuItem';

type IconProps = {
  size?: number;
  fill?: string;
};

interface MobileMenuItemTypes extends DesktopNavItemTypes {
  isActive: boolean;
  onClick: () => void;
};

const variantsLi = {
  open: { y: 0, opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 } } },
  closed: { y: 50, opacity: 0, transition: { y: { stiffness: 1000 } } },
};

const ICONS: Record<string, React.JSXElementConstructor<IconProps>> = {
  'WebLayoutIcon': WebLayoutIcon,
  'SimpleUserBodyIcon': SimpleUserBodyIcon,
};

export const MenuItem: React.FC<MobileMenuItemTypes> = ({
  iconName,
  name,
  isActive,
  onClick,
  url,
}) => {
  const CompIcon = ICONS[iconName];
  const iconFill = isActive ? '#fff' : '#000';
  const textColor = isActive ? 'C00' : 'F00';

  return (
    <motion.li
      variants={variantsLi}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <NavLink 
        to={url} 
        onClick={onClick} 
        className={cn("flex p-2 rounded-full gap-2", {
          'bg-F00': isActive
        })
      }>
        <CompIcon 
          fill={iconFill}
          size={24} 
        />
        <Typography
          size="2xl"
          tagName="span"
          whitespaceNowrap
          adaptive
          color={textColor}
        >
          {name}
        </Typography>
      </NavLink>
    </motion.li>
  );
};

import React from 'react';

// Components
import { Typography } from '@decide-ai/uikit';

// Types
import { CardLabelTypes } from '../types';

export const CardLabel: React.FC<CardLabelTypes> = ({ 
  label,
  children,
}) => (
  <div className='flex justify-between items-center w-full'>
    <Typography 
      color="F00" 
      size="base" 
      weight="medium"
    >
      {label}
    </Typography>

    {children}
  </div>
);
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography, IconText } from '@decide-ai/uikit';
import { ModalLayout } from '../../ModalLayout';

export const ReVerificationInfo: React.FC<{ feeSum: number; }> = ({ feeSum }) => {
  const { t } = useTranslation();
  
  const advantagesList = t('app.components.smartkit.reverification_payment_modal.warning_step.advantages_list', { 
    returnObjects: true 
  }) as string[];

  return (
    <ModalLayout.Info>
      <Typography size="base" color="F00">
        {t('app.components.smartkit.reverification_payment_modal.warning_step.text', { 
          reverification_fee: feeSum 
        })}
      </Typography>

      <ModalLayout.AdvantagesList>
        {advantagesList.map(item => (
          <IconText
            key={item}
            iconName="CheckSquareIcon"
            iconStroke="G00"
            text={item}
            textColor="F00"
            textSize="base"
          />
        ))}
      </ModalLayout.AdvantagesList>
    </ModalLayout.Info>
  );
}
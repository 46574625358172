// import { useSiweIdentity } from "ic-use-siwe-identity";
// import { useAccount, useDisconnect } from "wagmi";
import { useInternetIdentity } from '../contexts/InternetIdentityContext';
import { Identity } from '@dfinity/agent';
import { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { logdna } from '../utils/logger';
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import { useSiwsIdentity } from "ic-use-siws-identity";

interface UseIdentityReturn {
  principal: string | null;
  identity: Identity | null;
  isConnected: boolean;
  isInitializing: boolean;
  disconnect: () => Promise<void>;
  deviceId: string | null;
  deviceIdConfidence: number | null;
}

// function useEthereum() {
//   const { isConnected: e_isConnected } = useAccount();
//   const {
//     identity: e_identity,
//     clear: e_clear,
//     isInitializing: e_isInitializing,
//   } = useSiweIdentity();
//   const { disconnect: e_disconnect } = useDisconnect();

//   const disconnect = async () => {
//     await e_clear();
//     await e_disconnect();
//   };

//   return {
//     isConnected: e_isConnected,
//     identity: e_identity,
//     isInitializing: e_isInitializing,
//     disconnect,
//   };
// }


function useSolana() {
  const { connected, disconnect: s_disconnect } = useSolanaWallet();
  
  // TODO: Implement Solana identity
  const {
    identity: s_identity,
    clear: s_clear,
    isInitializing: s_isInitializing,
  } = useSiwsIdentity();

  const disconnect = async () => {
    await s_clear();
    await s_disconnect();
  };

  return {
    isConnected: connected,
    identity: s_identity,        // TODO
    isInitializing: s_isInitializing, // TODO
    disconnect,
  };
}

export function useIdentity(): UseIdentityReturn {
  // const {
  //   isConnected: e_isConnected,
  //   identity: e_identity,
  //   isInitializing: e_isInitializing,
  //   disconnect: e_disconnect,
  // } = useEthereum();


  const {
    isConnected: s_isConnected,
    identity: s_identity,
    isInitializing: s_isInitializing,
    disconnect: s_disconnect,
  } = useSolana();

  const {
    identity: ii_identity,
    disconnect: ii_disconnect,
    isInitializing: ii_isInitializing,
    isConnected: ii_isConnected,
  } = useInternetIdentity();

  const identity = ii_identity || s_identity;
  const principal = identity && identity.getPrincipal().toText();

  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [deviceIdConfidence, setDeviceIdConfidence] = useState<number | null>(null);

  const disconnect = async () => {
    await ii_disconnect();
    //await e_disconnect();
    await s_disconnect();
    logdna.addContext({
      user: {
        principal: null,
      },
    });
  };

  useEffect(() => {
    if (principal) {
      logdna.addContext({
        user: {
          principal: principal,
        },
      });
    }
  }, [principal]);

  useEffect(() => {
    const loadDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setDeviceId(result.visitorId);
      setDeviceIdConfidence(result.confidence.score);
      logdna.info(`user_id: ${principal}, deviceId: ${result.visitorId}, deviceIdConfidence: ${result.confidence.score}, comment: ${result.confidence.comment}`);

    };

    loadDeviceId();
  }, []);

  return {
    identity,
    principal,
    isConnected: ii_isConnected || s_isConnected,
    isInitializing: ii_isInitializing || s_isInitializing,
    deviceId,
    deviceIdConfidence,
    disconnect,
  };
}

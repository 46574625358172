import React from "react";
import { useNavigate } from "react-router-dom";

// Component
import { Button } from "@decide-ai/uikit";
import { SimpleModal } from '../../../components/uikit';

export const ErrorMessage: React.FC<{
  title: string;
  message: string;
}> = ({
  title,
  message,
}) => {
  const navigate = useNavigate();
  const makeRedirectonMain = () => navigate("/app");
  return (
    <div className="flex flex-col gap-5 max-w-sm m-auto">
      <SimpleModal
        title={title}
        subTitle={message}
        iconName="DangerCircleIcon"
        iconColor="D00"
      >
        <Button
          buttonText="Back to main page"
          fullWidth
          spaceBetween
          iconName="UndoRightRoundSquareIcon"
          onClick={makeRedirectonMain}
          size="medium"
          skin="standard"
          theme="black"
        />
      </SimpleModal>
    </div>
  );
}
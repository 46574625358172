import React from 'react';
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Components
import { Button } from '@decide-ai/uikit';

import { useTranslation } from 'react-i18next';

export const MainButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryString = window.location.search;

  const handlerButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`app/${queryString}`);
  };

  return (
    <motion.div
      variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 }}}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.7, delay: 1 }}
    >
      <Button
        buttonText={t('app.pages.landing.button-txt')}
        onClick={handlerButtonClick}
        size="large"
        skin="standard"
        theme="blue"
      />
    </motion.div>
  );
}

import React from 'react';

// Components
import { Typography } from '@decide-ai/uikit';
import { ErrorMessage } from '../ErrorMessage';

export const TotalAmount: React.FC<{
  totalAmountWithFee: number,
  icrc1FeeDCD: number | null,
  error?: string | null;
}> = ({ totalAmountWithFee, icrc1FeeDCD, error }) => {
  return (
    <div className="flex gap-2 flex-col">
      <div className="p-3 rounded-lg bg-A100 border-[1px] flex justify-between items-end">
        <div className="flex flex-col gap-0.5">
          <Typography size="xl" weight="bold" color="F00">
            {`${totalAmountWithFee} DCD`}
          </Typography>

          {icrc1FeeDCD && (
            <Typography size="base" color="F50">
              Fee: {icrc1FeeDCD ?? 0} DCD
            </Typography>
          )}

          {error && (
            <ErrorMessage error={error} />
          )}
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@decide-ai/uikit';
import { RefreshIndicator } from '../../../uikit';

import { RestartAmountPropTypes } from '../types';
import { useWallet } from '../../../../hooks/useWallet';

export const RestartAmount: React.FC<RestartAmountPropTypes> = ({ 
  balance,
  onRefresh,
}) => {


  const { t } = useTranslation();
  const { isFetching, isLoading } = useWallet();

  return (
    <div className='flex items-center justify-between'>
      <Typography size="sm" color="F00">
        {t('app.components.smartkit.payment_modal.restart_amount.title')}
      </Typography>
      <RefreshIndicator 
        isSpinning={isFetching || isLoading }
        onRefresh={onRefresh}
        value={`${balance} ${t('app.components.smartkit.payment_modal.restart_amount.unit')}`}
      />
    </div>
  );
}
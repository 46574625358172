import React from 'react';
import cn from 'classnames';
import { motion, AnimatePresence } from "framer-motion";

// Components
import {
  getColorByName, 
  CrossIcon,
  OutsideClick,
} from '@decide-ai/uikit';

// Types
import { ModalPropTypes } from './types';

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
export const closeSpring = { type: "spring", stiffness: 300, damping: 35 };

export const Modal: React.FC<ModalPropTypes> = ({ 
  children,
  closeModal 
}) => (
  <AnimatePresence>
    <div
      className={cn(
        'fixed top-0 left-0 right-0 bottom-0 z-50',
        'flex items-start justify-center px-5 py-16 overflow-y-scroll',
        'lg:items-center lg:px-10 lg:py-10',
        'bg-F00/80'
      )}
    >
      <OutsideClick onClick={closeModal}>
        <motion.div 
          className='relative flex flex-col gap-4 w-fit h-fit w-full bg-C100 rounded-2xl p-8 max-w-2xl'
          initial={{ opacity: 0, y: 50, scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
        >
          {children}

          <button 
            onClick={closeModal}
            className='absolute -top-4 -right-4 p-2 bg-C100 rounded-full drop-shadow-md'
            >
            <CrossIcon 
              stroke={getColorByName("F00")?.hex}
              />
          </button>
        </motion.div>
      </OutsideClick>
    </div>
  </AnimatePresence>
);
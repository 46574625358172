import React from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useConfig } from "../../../../hooks/useConfig";

// Components
import { Button, Typography, FeedbackAlert } from '@decide-ai/uikit';
import { ModalLayout } from '../ModalLayout';
import { ReVerificationFee } from './ReVerificationFee';
import { ReVerificationInfo } from './ReVerificationInfo';

export const WarningInfoStep: React.FC<{ onClick: () => void; }> = ({ onClick }) => {
  const { config } = useConfig();
  const { t } = useTranslation();

  const reverificationFee = config?.poh_reverification_fee ?? -1;

  return (
    <ModalLayout.WarningInfoContainer>
      <ModalLayout.MainTitle>
        <Typography size="4xl" weight="bold" color="F00">
          {t('app.components.smartkit.reverification_payment_modal.warning_step.title')}
        </Typography>
      </ModalLayout.MainTitle>

      <ModalLayout.Content>
        <ReVerificationFee feeSum={reverificationFee} />
        <ReVerificationInfo feeSum={reverificationFee} />
      </ModalLayout.Content>

      <ModalLayout.WarningPlate>
        <FeedbackAlert 
          skin="yellow"
          textColor="F00"
          title={t('app.components.smartkit.reverification_payment_modal.warning_step.fee_warning_title')}
          text={t('app.components.smartkit.reverification_payment_modal.warning_step.fee_warning_text')}
        />
      </ModalLayout.WarningPlate>

      <ModalLayout.Button
        className="pt-6 lg:pt-10"
      >
        <Button
          buttonText={t('app.components.smartkit.reverification_payment_modal.warning_step.button_text')}
          onClick={onClick}
          rounded="lg"
          iconName="ArrowRightIcon"
          size="large"
          skin="standard"
          fullWidth
          theme="black"
        />
      </ModalLayout.Button>
    </ModalLayout.WarningInfoContainer>
  );
}
import React from 'react';
import cn from 'classnames';

// Components
import { Typography } from '@decide-ai/uikit';

// Types
import { SelectableCardPropsTypes } from './types';

export const SelectableCard: React.FC<SelectableCardPropsTypes> = ({ 
  id,
  label, 
  text, 
  isActive = false, 
  onClick 
}) => {
  const textColor = isActive ? 'G00' : 'F00';
  const handlerClick = () => onClick && onClick(id);

  return (
    <div
      className={cn(
        'flex justify-between items-center gap-2',
        'px-6 py-3 border rounded-lg',
        'cursor-pointer hover:bg-G60', {
        'bg-G60 border-G00': isActive,
        'bg-white border-[1px] border-C00': !isActive
      })}
      onClick={handlerClick}
    >
      <div className="flex flex-col gap-0.5">
        {label && (
          <Typography 
            size="xs"
            color={textColor}
          >
            {label}
          </Typography>
        )}
        <Typography 
          size="lg"
          color={textColor}
        >
          {text}
        </Typography>
      </div>

      <div
        className={cn('p-2 rounded-full border-[1px] bg-white', {
          'border-transparent': isActive,
          'border-C00': !isActive
        })}
      >
        <div className={cn('w-2.5 h-2.5 rounded-full', {
          'bg-G00': isActive,
          'bg-C00': !isActive
        })} />
      </div>
    </div>
  );
}
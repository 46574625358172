import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Component
import { Info } from './Info';
import { Typography } from '@decide-ai/uikit';
import { Logo, SocialIcons, RadialGradients } from '../../components/uikit';
import { VerificationEmailLayout } from './VerificationEmailLayout';
import { VerificationContent } from './VerificationContent';

// Hooks
import { useTokenVerification } from './customHooks/useTokenVerification';

const VerificationEmailPage = () => {
  const { tokenId } = useParams();
  const { isLoading, error } = useTokenVerification(tokenId || "");
  const { t } = useTranslation();

  return (
    <VerificationEmailLayout>
      <VerificationEmailLayout.RadialGradient>
        <RadialGradients />
      </VerificationEmailLayout.RadialGradient>

      <VerificationEmailLayout.Wrapper>        
        <VerificationEmailLayout.Logo>
          <Logo className="w-full" />
        </VerificationEmailLayout.Logo>

        <VerificationEmailLayout.Info>
          <Info isError={!!error} isLoading={isLoading} />
        </VerificationEmailLayout.Info>

        <VerificationEmailLayout.Content>
          <VerificationContent isError={!!error} isLoading={isLoading} />
        </VerificationEmailLayout.Content>

        <VerificationEmailLayout.SocialButtons>
          <Typography color="F00" size="sm">
            {t('app.pages.verification_email.social')}
          </Typography>
          <SocialIcons />
        </VerificationEmailLayout.SocialButtons>
      
      </VerificationEmailLayout.Wrapper>
    </VerificationEmailLayout>
  );
};

export default VerificationEmailPage;
import "@solana/wallet-adapter-react-ui/styles.css";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import React, { useMemo } from "react";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { SiwsIdentityProvider } from "ic-use-siws-identity";
import { ic_siws_provider_actor } from "../../app/actors_by_env";
import { ic_siws_provider_types } from "../../app/canister_types";

export default function SolanaProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider autoConnect wallets={[]}>
        <WalletModalProvider>
          <SiwsIdentityProvider<ic_siws_provider_types._SERVICE>
            canisterId={ic_siws_provider_actor.canisterId}
            idlFactory={ic_siws_provider_actor.idlFactory}
          >
            {children}
          </SiwsIdentityProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}


import React from 'react';

// Components
import { DynamicIcon, getColorByName } from '@decide-ai/uikit';
import { TextSection } from '../TextSection';

// Types
import { SimpleModalPropTypes } from './types';

export const SimpleModal: React.FC<SimpleModalPropTypes> = ({ 
  title, 
  subTitle,
  children,
  iconName,
  iconColor = "B00",
}) => (
  <div className='flex flex-col items-center gap-6 w-full'>
    {iconName && (
      <DynamicIcon 
        size={80}
        strokeWidth={0.8}
        iconName={iconName}
        stroke={getColorByName(iconColor)?.hex}
      />
    )}

    <TextSection
      title={title}
      text={subTitle}
      textCenter
    />

    {children && children}
  </div>
);
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@decide-ai/uikit';
import { SimpleModal } from '../../../uikit';
import { useActors } from "../../../../hooks/actors";
import { useProfile } from "../../../../hooks/useProfile";
import { ModalLayout } from '../ModalLayout';

export const SuccessModalStep = () => {
  const [isResetPohLoading, setIsResetPohLoading] = useState(false);
  const { refreshProfile } = useProfile();
  const { decideid } = useActors();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickSuccessButton = async () => {
    setIsResetPohLoading(true);
    
    const res = await decideid.reverifyPoh();
    refreshProfile();
    if ("ok" in res) {
      setIsResetPohLoading(false);
      navigate("/poh");
    } else {
      setIsResetPohLoading(false);
      // TODO: Better error handling for reverifyPoh()
      console.error('Reverify result:', res.err);
    }
  };

  return (
    <SimpleModal 
      title={t('app.components.smartikit.success_reverification_payment_modal.title')}
      subTitle={t('app.components.smartikit.success_reverification_payment_modal.sub_title')}
      iconName="CheckCircleIcon"
    >
      <ModalLayout.Button>
        <Button
          buttonText={t('app.components.smartkit.success_reverification_payment_modal.success_button')}
          onClick={handleClickSuccessButton}
          rounded="lg"
          iconName="ArrowRightIcon"
          size="large"
          skin="standard"
          fullWidth
          theme="black"
          loading={isResetPohLoading}
        />
      </ModalLayout.Button>
    </SimpleModal>
  );
}

import React from "react";
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import { useWalletModal as useSolanaWalletModal } from "@solana/wallet-adapter-react-ui";
import { Button } from "@decide-ai/uikit";
import { useTranslation } from 'react-i18next';

export default function ConnectButton({ setModalOpen }: { setModalOpen: (isOpen: boolean) => void }) {
  const { setVisible } = useSolanaWalletModal();
  const { connecting, connected } = useSolanaWallet();
  const { t } = useTranslation();
  const handleClick = () => {
    if (connecting) return;
    if (!connected) {
      setVisible(true);
    } else {
      setModalOpen(true);
    }
  };

  const buttonText = connecting ? t('app.pages.authorization.solana.connecting') : t('app.pages.authorization.solana.wallet_text');

  return (<Button
      fullWidth
      disabled={connecting}
      onClick={handleClick}
      buttonText={buttonText}
      skin="standard"
      rounded="xl"
      size="large"
      theme="black"
      spaceBetween
    >
      
      <span className="relative inline-block align-middle">
        <img 
          src="/assets/solana-logo.svg"
          className="absolute -top-1/2 -translate-y-1/2 right-0 w-5 h-8 max-w-none" 
          alt="logo" 
        />
      </span>
    </Button>
  );
}

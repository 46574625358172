import React from 'react';
import cn from 'classnames';

// Components
import { Typography } from '@decide-ai/uikit';

// Types
import { TextSectionPropTypes } from './types';

export const TextSection: React.FC<TextSectionPropTypes> = ({
  title,
  text,
  textCenter,
  titleSize = "2xl",
}) => (
  <div className={cn(
    'flex flex-col gap-2', {
      'text-center': textCenter,
      'text-left': !textCenter
    })}>
    <Typography 
      color="F00"
      size={titleSize}
      weight='bold'
    >
      {title}
    </Typography>

    {text && (
      <Typography 
        color="F00"
        weight="light"
        size="base"
      >
        {text}
      </Typography>
    )}
  </div>
);
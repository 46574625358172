import React, { useState } from "react";

// AMW amplify
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';

// Components
import { ErrorMessageModal } from '../ErrorMessageModal';

// Api / Hooks
import fetchAnalysisResults from '../fetchersApi/fetchAnalysisResults';
import { useProfile } from '../../../hooks/useProfile';

// Type
import { LivenessProcessProps } from '../types';

// Constants
import { ANALYSIS_STATUS_PASS } from '../../../utils/constant';
import {useFetchStandard} from '../../../hooks/useFetchStandard';
import { logdna } from "../../../utils/logger";

export const LivenessProcess: React.FC<LivenessProcessProps> = ({
  sessionId,
  callback
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { hardRefreshProfileCache } = useProfile();
  const fetchStandard = useFetchStandard();

  const handleAnalysisComplete = async (): Promise<void> => {
    if (sessionId) {
      fetchAnalysisResults(sessionId, async (data) => {
        if (data && data.Status === ANALYSIS_STATUS_PASS) {
          await hardRefreshProfileCache();
        }

        callback(data);
      }, fetchStandard);
    }
  }

  const refreshPage = () => {
    window.location.reload();
  };

  if (!sessionId) {
    return null;
  }

  return (
    <React.Fragment>
      <FaceLivenessDetector
        sessionId={sessionId}
        region="us-east-1"
        onAnalysisComplete={handleAnalysisComplete}
        onError={(error) => {
          console.error(error);
          logdna.error(`FaceLivenessDetector issue: sessionId: ${sessionId} message: ${error}`);
          const errorMessage = error && error.error && error.error.message ? error.error.message : 'An unknown error occurred';
          setErrorMsg(errorMessage);
          setIsModalOpen(true);
        }}
      />
      {isModalOpen && (
        <ErrorMessageModal
          errorMsg={errorMsg}
          onCloseModal={() => setIsModalOpen(false)}
          onButtonClick={refreshPage}
        />
      )}
    </React.Fragment>
  );
}

import React from 'react';

// Components
import { Typography } from '@decide-ai/uikit';
import { MotionTextContainer } from '../MotionTextContainer';

// Types
import { SplashScreenPropTypes } from '../types';

export const TextSection: React.FC<SplashScreenPropTypes> = ({ 
  title, 
  text, 
  titleDelay, 
  titleDuration 
}) => (
  <div className='absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10'>
    <MotionTextContainer 
      delay={titleDelay}
      duration={titleDuration}
    >
      <Typography
        size='7xl'
        adaptive
        color='C100'
        weight='bold'
      >
        {title}
      </Typography>
    </MotionTextContainer>

    {text && (
      <div className='max-w-md m-auto mt-5'>
        <MotionTextContainer delay={0.6}>
          <Typography
            size='lg'
            adaptive
            color='C100'
            weight='light'
          >
            {text}
          </Typography>
        </MotionTextContainer>
      </div>
    )}
  </div>
);

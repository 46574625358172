import {FetchResponse, FetchStandardProps} from '../../../hooks/useFetchStandard';
import { logdna } from '../../../utils/logger';

const fetchCreateLiveness = async (
  user_id: string,
  deviceId: string | null,
  deviceIdConfidence: number | null,
  endpoint: string,
  fetchStandard: (data: FetchStandardProps) => Promise<FetchResponse>
) => {
  try {
    const additionalHeaders : { [key: string]: string; } = { 'DeviceId': '', 'DeviceIdConfidence': '' };
    if (deviceId !== null) {
      additionalHeaders['DeviceId'] = deviceId;
    }
    if (deviceIdConfidence !== null) {
      additionalHeaders['DeviceIdConfidence'] = deviceIdConfidence.toString();
    }

    const responceData = await fetchStandard({
      endpoint: `${endpoint}start_liveness_session`,
      method: 'POST',
      body: { user_id, deviceId, deviceIdConfidence },
      additionalHeaders: additionalHeaders,
      authorize: true
    });

    return responceData;
  } catch (error) {
    logdna.info(`Failed to fetchCreateLiveness for uid ${user_id}: ${error} `);
    console.error(error);
    throw error;
  }
};

export default fetchCreateLiveness;

import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";

// Components
import { Typography } from '@decide-ai/uikit';
import { CopyableText } from '../index';

// Utils
import { truncateMiddle } from '../../../utils/util';

export const QRWalletAddress: React.FC<{ 
  walletAddress: string; 
}> = ({ walletAddress }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="h-auto w-full max-w-[240px] mx-auto my-0">
        <QRCode
          className="h-auto max-w-full w-full"
          value={walletAddress}
          viewBox={`0 0 240 240`}
        />
      </div>


      <div className="flex flex-col items-center justify-center">
        <Typography size="lg" color="F00">
          {t('app.components.uikit.qr_wallet_address.title')}
        </Typography>
        <CopyableText 
          buttonText={truncateMiddle(walletAddress, 45)}
          textToCopy={walletAddress} 
          copyButton
        />
      </div>
    </div>
  );
}
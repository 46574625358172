import React from 'react';
import { Button } from "@decide-ai/uikit";
import { useSiwsIdentity } from "ic-use-siws-identity";
import { useTranslation } from 'react-i18next';
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";


export default function LoginButton() {
  const { t } = useTranslation();
  const { connected } = useSolanaWallet();

  const { login, isLoggingIn, isPreparingLogin } = useSiwsIdentity();

  const text = () => {
    if (isLoggingIn) {
      return t('app.pages.authorization.solana.signing_in');
    }
    if (isPreparingLogin) {
      return t('app.pages.authorization.solana.preparing');
    }
    return t('app.pages.authorization.solana.sign_in');
  };

  const disabled = isLoggingIn || !connected || isPreparingLogin;

  return <Button
      disabled={disabled}
      onClick={()=>{
        login();
      }}
      buttonText={
        text()
      }
      skin="inline"
      size="small"
      theme="black"
    />;
}

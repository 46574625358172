import React from 'react';
import { motion } from 'framer-motion';

// Components
import { Button } from '@decide-ai/uikit';

import { useLogout } from "../../../../../hooks/useLogout";

const variantsLi = {
  open: { y: 0, opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 } } },
  closed: { y: 50, opacity: 0, transition: { y: { stiffness: 1000 } } },
};

export const LogOutButton = () => {
  const logout = useLogout();
  return (
    <motion.li
      variants={variantsLi}
      className="absolute bottom-[60px] w-fill right-[20px] left-[20px]"
    >
      <Button
        buttonText="Log out"
        iconName="LogoutV3Icon"
        onClick={logout}
        rounded="full"
        size="medium"
        fullWidth
        skin="standard"
        theme="blue"
      />
    </motion.li>
  );
}

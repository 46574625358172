import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Logo, SocialIcons } from '../../../uikit';
import { Typography } from '@decide-ai/uikit';

export const Info = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-sm flex flex-col gap-6">
      <div className="w-48 h-14">
        <Logo theme="black" className="w-full" />
      </div>
      
      <Typography size="sm" color="F00">
        {t('app.components.layout.footer.text')}
      </Typography>
      <SocialIcons />

      <Typography size="sm" color="F60">
        {t('app.components.layout.footer.copy')}
      </Typography>
    </div>
  );
}
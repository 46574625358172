import React from 'react';

// Components
import {
  Button
} from '@decide-ai/uikit';
import { TextSection, Logo } from '../../../uikit';

// Data
import { DATA_COMMON } from '../../../../data/dataCommon';

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
export const closeSpring = { type: "spring", stiffness: 300, damping: 35 };

export const InfoPopup = () => {
  const { modal } = DATA_COMMON.about;

  const redirectOnDecideIdLanding = () => {
    if (window) {
      window.open("https://decideai.xyz/id", '_blank');
    }
  }

  return (
    <React.Fragment>
      <div className='h-10'>
        <Logo theme='black' />
      </div>
      <TextSection 
        title={modal.title}
        text={modal.text}
      />
      <div className='max-w-xs'>
        <Button
          buttonText="Read more"
          iconName="ForwardV2Icon"
          onClick={redirectOnDecideIdLanding}
          rounded="lg"
          size="medium"
          skin="standard"
          spaceBetween
          theme="black"
        />
      </div>
    </React.Fragment>
  );
}
import React from 'react';

// Components
import { BlockPlate } from '../../../../components/layout';
import { Typography } from '@decide-ai/uikit';
import { LabelBackground } from './LabelBackground';
import { Image } from './Image';
import { Link } from './Link';
import { CardLayout } from './CardLayout';
import { CardIcon } from './CardIcon';

export const PartnerCard: React.FC<{
  title: string;
  subTitle?: string;
  gradient?: string;
  image?: string;
  href?: string;
}> = ({ title, subTitle, gradient, image, href }) => (
  <BlockPlate>
    <CardLayout gradient={gradient}>
      {href && (
        <React.Fragment>
          <Link href={href} />
          <CardLayout.Icon>
            <CardIcon />
          </CardLayout.Icon>
        </React.Fragment>
      )}

      {image && (
        <Image image={image} />
      )}

      <CardLayout.Info>
        <LabelBackground>
          <Typography color="F00" size="xl">{title}</Typography>
        </LabelBackground>
        {subTitle && (
          <div className="py-2.5 lg:items-center self-start lg:self-end lg:!text-right">
            <Typography color="F00" size="base">{subTitle}</Typography>
          </div>
        )}
      </CardLayout.Info>
    </CardLayout>
  </BlockPlate>
);
import React from 'react';
import cn from "classnames";

// Components
import { Typography } from '@decide-ai/uikit';
import { CopyableText } from '../../uikit';

export const LabelText: React.FC<{
  color?: string | null,
  position?: "end" | "start",
  label?: string,
  text: string,
  textToCopy?: string | null;
  children?: React.ReactNode;
}> = ({ 
  color = "bg-white/70",
  position = "start",
  textToCopy = null,
  label,
  text,
  children,
 }) => (
  <div className={cn(
    color,
    "py-2.5 px-6 w-full lg:w-auto",
    "rounded-xl lg:rounded-full lg:items-center",
    "flex flex-col lg:flex-row gap-1",
    {
      "lg:self-end": position === "end",
      "lg:self-start": position === "start",
    }
  )}>
    {label && (
      <Typography size="base" color="F70">
        {label}
      </Typography>
    )}

    {textToCopy ? (
      <CopyableText 
        textToCopy={textToCopy}
        buttonText={text}
        copyButton
      >
        <Typography size="base" color="F00">
          {text}
        </Typography>  
      </CopyableText>
    ) : (
      <Typography size="base" color="F00">
        {text}
      </Typography>
    )}

    {children}
  </div>
)
import React from 'react';

import { ForwardV2Icon, getColorByName } from '@decide-ai/uikit';

export const CardIcon = () => (
  <div className="w-10 h-10 flex items-center justify-center bg-H110 group-hover:bg-H90 transition-all rounded-full">
    <ForwardV2Icon 
      stroke={getColorByName('F00')?.hex} 
      size={24}
    />
  </div>
);
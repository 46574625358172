import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import { Button } from "@decide-ai/uikit";

// Components
import ConnectButton from "./ConnectButton";
import { Modal, Portal } from '../../../components/uikit';
import LoginButton from "./LoginButton";
import { useSiwsIdentity } from "ic-use-siws-identity";


export function SolanaLogin() {
  const { t } = useTranslation();
  const { connected, disconnect , publicKey} =  useSolanaWallet();
  const { clear, identity, identityAddress } =
    useSiwsIdentity();

  // If the user is not connected, clear the session.
  useEffect(() => {
    if (identity && connected && !publicKey) {
      clear();
    }
  }, [connected, publicKey, clear, identity]);

  // If the user switches to a different address, clear the session.
  useEffect(() => {
    if (identityAddress && publicKey && !identityAddress.equals(publicKey)) {
      clear();
    }
  }, [publicKey, clear, identityAddress]);



  const { prepareLogin, isPrepareLoginIdle, prepareLoginError, loginError } =
    useSiwsIdentity();

  /**
   * Preload a Siws message on every address change.
   */
  useEffect(() => {
    if (!isPrepareLoginIdle || !publicKey) return;
    prepareLogin();
  }, [publicKey, prepareLogin, isPrepareLoginIdle]);

  /**
   * Show an error toast if the prepareLogin() call fails.
   */
  useEffect(() => {
    if (prepareLoginError) {
      window.alert(prepareLoginError.message);
    }
  }, [prepareLoginError]);

  /**
   * Show an error toast if the login call fails.
   */
  useEffect(() => {
    if (loginError) {
      window.alert(loginError.message);
    }
  }, [loginError]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (connected) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [connected]);

  return (
    <div>
      {<ConnectButton setModalOpen={setIsModalOpen} />}
      
      {isModalOpen && (
        <Portal>
          <Modal closeModal={() => setIsModalOpen(false)}>
            <p>{t('app.pages.authorization.solana.solana_modal_text')}</p>
            <div className="grid grid-cols-1 gap-2">
              <LoginButton />
              <Button
                buttonText="Disconnect"
                onClick={disconnect}
                skin="inline"
                size="small"
                theme="black"
              />
            </div>
            {connected && <small>{t('app.pages.authorization.solana.signed_in_message', { publicKey })}</small>}
          </Modal>
        </Portal>
      )}
    </div>
  )
}